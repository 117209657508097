import React from "react"
import { Link, graphql } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/layout"

import Bio from '../components/bio'
import { rhythm } from '../utils/typography'

class MeetupsIndex extends React.Component {
    render() {
        // console.log("props", this.props)
        const pageLinks = []
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const meetups = get(this, 'props.data.meetups.edges')
        meetups.forEach(post => {
            if (post.node.path !== '/404/') {
                pageLinks.push(
                    <li
                        key={post.node.frontmatter.path}
                        style={{
                            marginBottom: rhythm(1 / 4),
                        }}
                    >
                        <Link
                            style={{ boxShadow: 'none' }}
                            to={post.node.frontmatter.path}
                        >
                            {post.node.frontmatter.title}
                        </Link>
                    </li>
                )
            }
        })

        return (
			<Layout location={this.props.location} title={siteTitle}>
                {/*<Helmet*/}
                    {/*title={get(this, 'props.data.site.siteMetadata.title')}*/}
                {/*/>*/}
                <Bio />
                <div>
                    <h2>Tous les reports</h2>
                    <ul>{pageLinks}</ul>
                </div>
			</Layout>
        )
    }
}

export default MeetupsIndex

export const pageQuery = graphql`
    query AllMeetupsQuery {
        site {
            siteMetadata {
                title
            }
        }
        meetups: allMarkdownRemark(
            filter: { frontmatter: { layout: { eq: "Meetup" } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                    }
                }
            }
        }
    }
`
